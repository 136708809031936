@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap");

.brand-btn {
  @apply py-2 px-4 rounded-md bg-black text-white dark:bg-white dark:text-slate-900 hover:bg-gray-700 dark:hover:bg-gray-200 transition-colors;
}
.brand-input {
  @apply rounded p-1 bg-white dark:bg-slate-500 text-black dark:text-white border border-slate-400 placeholder-slate-300 hover:border-slate-700 dark:border-slate-700 dark:hover:border-slate-100 dark:placeholder-slate-100;
}
.brand-logo {
  font-family: "Luckiest Guy", cursive;
}
.default {
  @apply bg-white text-black dark:bg-slate-200 dark:text-slate-900;
}
.success {
  @apply bg-green-400 text-gray-100 dark:bg-green-700 dark:text-gray-300;
}
.danger {
  @apply bg-red-400 text-black dark:bg-red-700 dark:text-gray-300;
}
.info {
  @apply bg-blue-400 text-gray-100 dark:bg-blue-700 dark:text-gray-300;
}
